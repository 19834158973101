import React, { useMemo, HTMLProps } from "react";

import MuiLink, { LinkProps } from "@material-ui/core/Link";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";

import { getNavigateParams, IUrlOpts } from "@utils/navigate";
import { useLocation } from "@hooks/use-location";

import type { PathIndex } from "~/model/paths";
import type { ILocationState } from "~/model/view";

type ExcludeGatsbyProps = (
    | keyof HTMLProps<HTMLAnchorElement>
    | "to"
    | "color"
    | "innerRef"
);
type ExcludeMuiProps = (
    | "ref"
)

type GatsbyProps = Omit<GatsbyLinkProps<ILocationState>, ExcludeGatsbyProps>;
type MuiLinkProps = Omit<LinkProps, ExcludeMuiProps>;

export interface ILinkProps extends GatsbyProps, MuiLinkProps {
    to: PathIndex;
    back?: boolean;
    savePath?: boolean;
    saveState?: boolean;
    keepQuery?: boolean;
    removeQuery?: string[];
    external?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const Link: React.FC<ILinkProps> = ({
    to,
    state,
    back,
    savePath,
    saveState,
    keepQuery,
    removeQuery,
    className,
    style,
    color = "inherit",
    underline = "none",
    external = false,
    ...props
}) => {
    const location = useLocation();

    const {
        to: navPath,
        options: {
            state: navState,
        },
    } = useMemo(() => (
        getNavigateParams(to, {
            state,
            back,
            savePath,
            saveState,
            keepQuery,
            removeQuery,
            // validate: true,
        }, location)
    ), [to, state, back, savePath, saveState, keepQuery, removeQuery, location]);

    const navProps = useMemo(() => (
        external ? {
            href: navPath,
        } : {
            to: navPath,
            state: navState,
        }
    ), [external, navPath, navState]);

    return (
        <MuiLink
            component={external ? "a" : GatsbyLink}
            className={className}
            style={style}
            color={color}
            underline={underline}
            {...navProps}
            {...props}
        />
    );
};

export interface ILooseLinkProps extends Omit<ILinkProps, "to"> {
    to: string | IUrlOpts;
}

export const LooseLink: React.FC<ILooseLinkProps> = (props) => (
    <Link {...props} to={props.to as PathIndex} />
);